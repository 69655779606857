import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Dialog, {DialogProps} from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import LaunchOutlinedIcon from '@mui/icons-material/LaunchOutlined';
// import {useMoralis} from 'react-moralis';
import {getExplorer} from '../networks';
import { useContext } from 'react';
import { UserContext } from '../../contexts/userContext';
import Address from '../Address/Address';
import { useDomain } from '../../hooks/useDomain';


interface AccountDialogProps extends DialogProps {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function AccountDialog(props: AccountDialogProps) {
  // const {account, chainId, logout} = useMoralis();
  const user = useContext(UserContext);
  const {domain, setDomain, fetchDomain} = useDomain(user.moralis);

  const {open, setOpen} = props;

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle
        sx={{
          color: 'white',
          backgroundImage: 'linear-gradient(to right top, #678cf2 0%, #ff59bd 100%)',
          borderColor: '#ff59bd',
          padding: '0px 0px 0px 0px !important',
          borderWidth: 2,
          fontFamily: 'Retro Rollers',
          fontSize: '32px',
          textShadow: '3px 3px 2px rgba(211, 13, 162, 0.651)',
          textAlign: 'center',
        }}>Account
        </DialogTitle>
      <DialogContent sx={{p: 2.5, background: '#3b3b3b', borderColor: '#ff59bd', borderLeftWidth: 2, borderRightWidth: 2,}}>
        <Card sx={{mt: 1.25, borderRadius: '1rem', backgroundImage: 'linear-gradient(to right top, #678cf2 0%, #ff59bd 100%)',}}>
          <CardContent sx={{p: 1.875, color: 'white'}}>
            <Address avatar="left" size={6} copyable sx={{fontSize: '20px', color: 'white'}} />
          </CardContent>
          <CardActions>
            <Button
              href={`${getExplorer(user.chain!)}address/${user.acct}`}
              target="_blank"
              rel="noreferrer"
              startIcon={<LaunchOutlinedIcon />}
              sx={{textTransform: 'none', borderRadius: 99, fontFamily: 'Retro Rollers', color: '#860e6d', fontSize: '20px', textShadow: '2px 2px 2px rgba(211, 13, 162, 0.651)'}}
            >
              View on Explorer
            </Button>
          </CardActions>
        </Card>
      </DialogContent>
      <DialogActions sx={{borderColor: '#ff59bd',
          borderLeftWidth: 2, borderRightWidth: 2, borderBottomWidth: 2, background: '#3b3b3b',}}
      >
        <Button
          size="large"
          variant="contained"
          onClick={async () => {
            await user.logOut();
            window.localStorage.removeItem('connectorId');
            setOpen(false);
            setDomain(null);
            console.log(domain);
          }}
          sx={{
            width: '100%',
            padding: '0px 0px 0px 0px !important',
            borderRadius: 3,
            fontFamily: 'Retro Rollers',
            fontSize: '25px',
            textShadow: '3px 3px 2px rgba(211, 13, 162, 0.651)',
            backgroundImage: 'linear-gradient(to right top, #678cf2 0%, #ff59bd 100%)',}}
        >
          Disconnect Wallet
        </Button>
      </DialogActions>
    </Dialog>
  );
}
