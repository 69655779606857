import { useState } from "react";

export const useDomain = (Moralis: any) => {
    const [domain, setDomain] = useState(null);

    async function fetchDomain(address: string | null) {
        if (!(address)) return;
        const options = {
          address: address
        };
        try {
          const resolve = await Moralis.Web3API.resolve.resolveAddress(options);
          // console.log(resolve);
          setDomain(resolve.name);
        } catch (error) {
          if (error.response) {
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
          } else if (error.request) {
              /*
               * The request was made but no response was received, `error.request`
               * is an instance of XMLHttpRequest in the browser and an instance
               * of http.ClientRequest in Node.js
               */
              console.log(error.request);
          } else {
              // Something happened in setting up the request and triggered an Error
              // console.log('Error', error.message);
          }
          // console.log(error);
          setDomain(null);
      }}

      return {domain, setDomain, fetchDomain};
}
