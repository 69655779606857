import { useEffect } from 'react';
import '../../App.css';
import { useMoralisWeb3Api } from 'react-moralis';
import { useHighScores } from '../../hooks/useHighScores';
import { UserContext } from '../../contexts/userContext';
import { useContext, useState } from 'react';
// import { useDomain } from '../../hooks/useDomain'
import {getEllipsisTxt} from '../formatters';

import refresh from '../../media/refresh2.png'

const BoardElement = ({score}) => {
    const user = useContext(UserContext);
    const [domain, setDomain] = useState<string | null>(null);
    const Web3Api = useMoralisWeb3Api();

    useEffect(() => {
        const fetchDomain = async (address: string | null) => {
          if (!(address)) return;
          const options = {address: address};
            try {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                const resolve = await Web3Api.resolve.resolveAddress(options);
                if (resolve.name === "" || null || {"name": null}){
                    setDomain(resolve.name);
                } else {
                    setDomain(resolve.name)
                }
            } catch (error) {
              if (error.response) {
                  console.log(error.response.data);
                  console.log(error.response.status);
                  console.log(error.response.headers);
              } else if (error.request) {
                  /*
                   * The request was made but no response was received, `error.request`
                   * is an instance of XMLHttpRequest in the browser and an instance
                   * of http.ClientRequest in Node.js
                   */
                  console.log(error.request);
              } else {
                  // Something happened in setting up the request and triggered an Error
              }
          }
        };

        fetchDomain(score.walletAddress);
      }, [score.walletAddress]);

    if(user.acct === score.walletAddress.toLowerCase()) {
        return (
            <tr className='placement'>
                <td>{score.rank}</td>
                <td>{score.score}</td>
                <td>{score.tokenId}</td>
                <td className=''> {domain ?? getEllipsisTxt(user.acct, 5)}</td>
        </tr>
        )
    }else
    return (
        <tr>
            <td>{score.rank}</td>
            <td>{score.score}</td>
            <td>{score.tokenId}</td>
            <td className='' >{domain ?? getEllipsisTxt(score.walletAddress, 5)}</td>
        </tr>
    )
}

function Board() {
    const {highScore, fetchHighScores} = useHighScores();
    const user = useContext(UserContext);

    useEffect(() => {
        fetchHighScores();
    }, [user.acct]);

    function Refresh() {
        fetchHighScores();
    }

    return (
        <div className='Board red-glassmorphism text-white'>
            <div className='Font Shadow text-4xl theader flex align-center items-center gap-x-2'>
                &nbsp;
                <h1 className='bfont placement2'>Global High Scores</h1>
                <button onClick={() => Refresh()}>
                    <img className="w-6" src={refresh} alt="" />
                </button>
            </div>
            <table>
                <thead className='Font Shadow hfont placement2'>
                    <tr>
                        <th className='tsmall' colSpan={1}>Rank</th>
                        <th className='' colSpan={1}>Score</th>
                        <th className='tsmall' colSpan={1}>Roller</th>
                        <th className='' colSpan={1}>Wallet</th>
                    </tr>
                </thead>
                <tbody className='Font tfont'>
                {
                    highScore.map((score) =>
                        <BoardElement score={score} key={score.rank} />)
                }
                </tbody>
                <tfoot>
                    <tr>
                        <th colSpan={5}></th>
                    </tr>
                </tfoot>
            </table>
        </div>
    );
}

export default Board;
