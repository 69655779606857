import Moralis from 'moralis';
import Metamask from './WalletIcons/metamaskWallet.png';
import Coin98 from './WalletIcons/Coin98.png';
import WalletConnect from './WalletIcons/wallet-connect.svg';
import MathWallet from './WalletIcons/MathWallet.svg';
import TokenPocket from './WalletIcons/TokenPocket.svg';
import SafePal from './WalletIcons/SafePal.svg';
import TrustWallet from './WalletIcons/TrustWallet.png';

interface Connector {
  title: string;
  icon: string;
  connectorId?: Moralis.Web3ProviderType;
  priority: number;
}

export const connectors: Connector[] = [
  {
    title: 'Metamask',
    icon: Metamask,
    priority: 1,
  },
  {
    title: 'WalletConnect',
    icon: WalletConnect,
    connectorId: 'walletconnect',
    priority: 2,
  },
];
