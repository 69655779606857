import {DialogContentText} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog, {DialogProps} from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import {connectors} from './config';

import { UserContext } from '../../contexts/userContext';
import { useContext } from 'react';


interface ConnectWalletDialogProps extends DialogProps {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const CHAIN_ID = process.env.REACT_APP_CHAIN_ID
  ? parseInt(process.env.REACT_APP_CHAIN_ID)
  : 4;

export default function ConnectWalletDialog(props: ConnectWalletDialogProps) {
  const user = useContext(UserContext);
  const {open, setOpen} = props;

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle
        sx={{
          color: 'white',
          backgroundImage: 'linear-gradient(to right top, #678cf2 0%, #ff59bd 100%)',
          borderColor: '#ff59bd',
          padding: '0px 0px 0px 0px !important',
          borderWidth: 2,
          fontFamily: 'Retro Rollers',
          fontSize: '32px',
          textShadow: '3px 3px 2px rgba(211, 13, 162, 0.651)',
          textAlign: 'center',
        }}
      >
        Connect Wallet
      </DialogTitle>
      <DialogContent sx={{
          padding: '10px !important',
          background: '#3b3b3b',
          borderColor: '#ff59bd',
          borderLeftWidth: 2,
          borderRightWidth: 2,
          borderBottomWidth: 2,}}>
        <Grid container>
          {connectors.map(({title, icon, connectorId}) => (
            <Grid item xs={7} key={title}>
              <Button
                onClick={async () => {
                  try {
                    await user.auth({
                      provider: connectorId,
                      chainId: CHAIN_ID,
                    });
                    window.localStorage.setItem(
                      'connectorId',
                      connectorId || 'injected'
                    );
                    setOpen(false);
                  } catch (e) {
                    console.error(e);
                  }
                }}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  width: '100%',
                  textTransform: 'none',
                  margin: '0px !important',
                  fill: 'rgb(255, 102, 0)'
                }}
              >
                <Box
                  component="img"
                  src={icon}
                  alt={title}
                  sx={{
                    alignSelf: 'center',
                    fill: 'rgb(40, 13, 95)',
                    flexShrink: 0,
                    mb: 1,
                    height: 30,
                  }}
                />
                <DialogContentText sx={{
                  fontSize: 20,
                  fontWeight: 500,
                  fontFamily: 'Retro Rollers',
                  color: 'white',
                  textShadow: '3px 3px 1px rgba(211, 13, 162, 0.651)',}}>
                  {title}
                </DialogContentText>
              </Button>
            </Grid>
          ))}
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
