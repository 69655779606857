import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import './App.css';
import Main from './components/Main';
import Mint from './components/Mint';
import Leaderboard from './components/Leaderboard';
import { UserProvider } from "./contexts/userContext";

function App() {
  return (
    <UserProvider>
      <Router>
        <Routes>
          <Route index element={<Main />} />
            <Route path="/mint" element={<Mint />} />
            <Route path="/leaderboard" element={<Leaderboard />} />
        </Routes>
      </Router>
    </UserProvider>

  );
}

export default App;
