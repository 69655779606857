// import NFTBalance from './NFTBalances';
import floor from '../../media/floor.png';
import roof from '../../media/roof.png';
import roller from '../../media/roller1.gif';
import stars from '../../media/stars.gif';
import { useIPFS } from '../../hooks/useIPFS';
import { useSelected } from "../../hooks/useSelected";
import NFTBalance from './NFTBalance';
import Marquee from 'react-fast-marquee';
import { saveAs } from 'file-saver';
import { useContext } from 'react';
import { UserContext } from '../../contexts/userContext';

function Display(args) {
    const CONTRACT_URI = 'ipfs://QmWFo2qnme5K28JLwDYjMCAATiLYqgH82AfqQZbiWi3FjX/';
    const EMOTE_URI = 'ipfs://QmPCPQDHV2JHdhQ3GXRnbLTkY6cupiv7duDFNjmZ25yrpb/';
    const { selected, setSelected } = useSelected();
    // const { isAuthenticated, account } = useMoralis();
    const user = useContext(UserContext);
    const { resolveLink } = useIPFS();

    function downloadGif() {
        if(selected > 0){
            saveAs(resolveLink(`${CONTRACT_URI}${selected}.gif`), `roller#${selected}.gif`)
        } else {
            return;
        }
    }

    function downloadEmote() {
        if(selected > 0){
            saveAs(resolveLink(`${EMOTE_URI}${selected}.gif`), `roller#${selected}.gif`)
        } else {
            return;
        }
    }

    function Images() {
        if(selected > 0){
            return (
                <img className='char' src={resolveLink(`${CONTRACT_URI}${selected}.gif`)} alt="" />
            )
        } else {
            return (
                <img className='char' src={roller} alt="" />
            );
        }
    }

    function Roller() {
        if(selected <= 0){
            return (
                <div className='char-buttons text-white'></div>
            );
        } else {
            return (
                <div className='char-buttons text-white text-2xl'>
                    <p className='Font Shadow'>ROLLER</p>
                    <p className='Font Shadow'>#{selected}</p>
                    <button className="deselect" onClick={() => deselect()}></button>
                </div>
            )
        }
    }

    function deselect() {
        setSelected(0);
    }

    if(user.isAuth && user.acct){
        return (
            <div>
                <Marquee direction='right' speed={0} gradient={false} loop={0}>
            <img className="h-32 w-32" src={roof} />
            <img className="h-32 w-32" src={roof} />
            <img className="h-32 w-32" src={roof} />
            <img className="h-32 w-32" src={roof} />
            <img className="h-32 w-32" src={roof} />
            <img className="h-32 w-32" src={roof} />
            <img className="h-32 w-32" src={roof} />
            <img className="h-32 w-32" src={roof} />
            <img className="h-32 w-32" src={roof} />
            <img className="h-32 w-32" src={roof} />
            <img className="h-32 w-32" src={roof} />
            <img className="h-32 w-32" src={roof} />
            <img className="h-32 w-32" src={roof} />
            <img className="h-32 w-32" src={roof} />
            <img className="h-32 w-32" src={roof} />
            <img className="h-32 w-32" src={roof} />
            <img className="h-32 w-32" src={roof} />
            <img className="h-32 w-32" src={roof} />
            <img className="h-32 w-32" src={roof} />
            <img className="h-32 w-32" src={roof} />
            <img className="h-32 w-32" src={roof} />
            <img className="h-32 w-32" src={roof} />
            <img className="h-32 w-32" src={roof} />
            <img className="h-32 w-32" src={roof} />
            <img className="h-32 w-32" src={roof} />
            <img className="h-32 w-32" src={roof} />
            <img className="h-32 w-32" src={roof} />
            <img className="h-32 w-32" src={roof} />
            <img className="h-32 w-32" src={roof} />
            <img className="h-32 w-32" src={roof} />
            <img className="h-32 w-32" src={roof} />
            <img className="h-32 w-32" src={roof} />
            <img className="h-32 w-32" src={roof} />
            <img className="h-32 w-32" src={roof} />
            <img className="h-32 w-32" src={roof} />
            <img className="h-32 w-32" src={roof} />
            <img className="h-32 w-32" src={roof} />
            <img className="h-32 w-32" src={roof} />
            <img className="h-32 w-32" src={roof} />
            <img className="h-32 w-32" src={roof} />
            <img className="h-32 w-32" src={roof} />
            <img className="h-32 w-32" src={roof} />
            <img className="h-32 w-32" src={roof} />
            <img className="h-32 w-32" src={roof} />
                </Marquee>
                <div className='mint-grid'>
                    <div className="mint-image">
                        <img className='actual' src={stars} alt="" />
                    </div>
                    <div className="character">
                        <Roller/>
                        <Images/>
                        <div className='char-buttons'>
                            <button className="gif" onClick={() => downloadGif()}></button>
                            <button className="emote" onClick={() => downloadEmote()}></button>
                        </div>
                    </div>
                    <div className='header Shadow Font text-white floating2'>
                        <p>&#8592; Select your Roller &#8594;</p>
                    </div>
                    <div className='nft'>
                        <NFTBalance setSelected={setSelected}/>
                    </div>
                </div>
                <Marquee direction='right' speed={50} gradient={false} loop={0}>
                    <img className="h-32 w-32" src={floor} />
                    <img className="h-32 w-32" src={floor} />
                    <img className="h-32 w-32" src={floor} />
                    <img className="h-32 w-32" src={floor} />
                    <img className="h-32 w-32" src={floor} />
                    <img className="h-32 w-32" src={floor} />
                    <img className="h-32 w-32" src={floor} />
                    <img className="h-32 w-32" src={floor} />
                    <img className="h-32 w-32" src={floor} />
                    <img className="h-32 w-32" src={floor} />
                    <img className="h-32 w-32" src={floor} />
                    <img className="h-32 w-32" src={floor} />
                    <img className="h-32 w-32" src={floor} />
                    <img className="h-32 w-32" src={floor} />
                    <img className="h-32 w-32" src={floor} />
                    <img className="h-32 w-32" src={floor} />
                    <img className="h-32 w-32" src={floor} />
                    <img className="h-32 w-32" src={floor} />
                    <img className="h-32 w-32" src={floor} />
                    <img className="h-32 w-32" src={floor} />
                    <img className="h-32 w-32" src={floor} />
                    <img className="h-32 w-32" src={floor} />
                    <img className="h-32 w-32" src={floor} />
                    <img className="h-32 w-32" src={floor} />
                    <img className="h-32 w-32" src={floor} />
                    <img className="h-32 w-32" src={floor} />
                    <img className="h-32 w-32" src={floor} />
                    <img className="h-32 w-32" src={floor} />
                    <img className="h-32 w-32" src={floor} />
                    <img className="h-32 w-32" src={floor} />
                    <img className="h-32 w-32" src={floor} />
                    <img className="h-32 w-32" src={floor} />
                    <img className="h-32 w-32" src={floor} />
                    <img className="h-32 w-32" src={floor} />
                    <img className="h-32 w-32" src={floor} />
                    <img className="h-32 w-32" src={floor} />
                    <img className="h-32 w-32" src={floor} />
                    <img className="h-32 w-32" src={floor} />
                    <img className="h-32 w-32" src={floor} />
                    <img className="h-32 w-32" src={floor} />
                </Marquee>
            </div>
        );
    } else {
        return (
            <div>
                <Marquee direction='right' speed={0} gradient={false} loop={0}>
            <img className="h-32 w-32" src={roof} />
            <img className="h-32 w-32" src={roof} />
            <img className="h-32 w-32" src={roof} />
            <img className="h-32 w-32" src={roof} />
            <img className="h-32 w-32" src={roof} />
            <img className="h-32 w-32" src={roof} />
            <img className="h-32 w-32" src={roof} />
            <img className="h-32 w-32" src={roof} />
            <img className="h-32 w-32" src={roof} />
            <img className="h-32 w-32" src={roof} />
            <img className="h-32 w-32" src={roof} />
            <img className="h-32 w-32" src={roof} />
            <img className="h-32 w-32" src={roof} />
            <img className="h-32 w-32" src={roof} />
            <img className="h-32 w-32" src={roof} />
            <img className="h-32 w-32" src={roof} />
            <img className="h-32 w-32" src={roof} />
            <img className="h-32 w-32" src={roof} />
            <img className="h-32 w-32" src={roof} />
            <img className="h-32 w-32" src={roof} />
            <img className="h-32 w-32" src={roof} />
            <img className="h-32 w-32" src={roof} />
            <img className="h-32 w-32" src={roof} />
            <img className="h-32 w-32" src={roof} />
            <img className="h-32 w-32" src={roof} />
            <img className="h-32 w-32" src={roof} />
            <img className="h-32 w-32" src={roof} />
            <img className="h-32 w-32" src={roof} />
            <img className="h-32 w-32" src={roof} />
            <img className="h-32 w-32" src={roof} />
            <img className="h-32 w-32" src={roof} />
            <img className="h-32 w-32" src={roof} />
            <img className="h-32 w-32" src={roof} />
            <img className="h-32 w-32" src={roof} />
            <img className="h-32 w-32" src={roof} />
            <img className="h-32 w-32" src={roof} />
            <img className="h-32 w-32" src={roof} />
            <img className="h-32 w-32" src={roof} />
            <img className="h-32 w-32" src={roof} />
            <img className="h-32 w-32" src={roof} />
            <img className="h-32 w-32" src={roof} />
            <img className="h-32 w-32" src={roof} />
            <img className="h-32 w-32" src={roof} />
            <img className="h-32 w-32" src={roof} />
                </Marquee>
                <div className='mint-grid'>
                    <div className="mint-image">
                        <img className='actual' src={stars} alt="" />
                    </div>
                </div>
                <Marquee direction='right' speed={50} gradient={false} loop={0}>
                <img className="h-32 w-32" src={floor} />
                    <img className="h-32 w-32" src={floor} />
                    <img className="h-32 w-32" src={floor} />
                    <img className="h-32 w-32" src={floor} />
                    <img className="h-32 w-32" src={floor} />
                    <img className="h-32 w-32" src={floor} />
                    <img className="h-32 w-32" src={floor} />
                    <img className="h-32 w-32" src={floor} />
                    <img className="h-32 w-32" src={floor} />
                    <img className="h-32 w-32" src={floor} />
                    <img className="h-32 w-32" src={floor} />
                    <img className="h-32 w-32" src={floor} />
                    <img className="h-32 w-32" src={floor} />
                    <img className="h-32 w-32" src={floor} />
                    <img className="h-32 w-32" src={floor} />
                    <img className="h-32 w-32" src={floor} />
                    <img className="h-32 w-32" src={floor} />
                    <img className="h-32 w-32" src={floor} />
                    <img className="h-32 w-32" src={floor} />
                    <img className="h-32 w-32" src={floor} />
                    <img className="h-32 w-32" src={floor} />
                    <img className="h-32 w-32" src={floor} />
                    <img className="h-32 w-32" src={floor} />
                    <img className="h-32 w-32" src={floor} />
                    <img className="h-32 w-32" src={floor} />
                    <img className="h-32 w-32" src={floor} />
                    <img className="h-32 w-32" src={floor} />
                    <img className="h-32 w-32" src={floor} />
                    <img className="h-32 w-32" src={floor} />
                    <img className="h-32 w-32" src={floor} />
                    <img className="h-32 w-32" src={floor} />
                    <img className="h-32 w-32" src={floor} />
                    <img className="h-32 w-32" src={floor} />
                    <img className="h-32 w-32" src={floor} />
                    <img className="h-32 w-32" src={floor} />
                    <img className="h-32 w-32" src={floor} />
                    <img className="h-32 w-32" src={floor} />
                    <img className="h-32 w-32" src={floor} />
                    <img className="h-32 w-32" src={floor} />
                    <img className="h-32 w-32" src={floor} />
                </Marquee>
            </div>
        );
    }
}

export default Display;
