import {useState} from 'react';
import contractAbi from '../utils/contract.json';

const CONTRACT_ADDRESS = process.env.REACT_APP_CONTRACT_ADDRESS;

export const useMint = (Moralis: any) => {
  // const user = useContext(UserContext);
  // const {Moralis} = useMoralis();
  const [isMinting, setIsMinting] = useState(false);

  async function executeTransaction(opts: any) {
    const defaultOpts = {
      contractAddress: CONTRACT_ADDRESS,
      abi: contractAbi,
    };

    if (!CONTRACT_ADDRESS) {
      console.log('ERROR: Missing REACT_APP_CONTRACT_ADDRESS env variable');
      return '';
    }

    const mergedOpts = {...defaultOpts, ...opts};
    const tx = await Moralis.executeFunction(mergedOpts);
    return tx;
  }

  async function publicMint(qty: number) {
    const options = {
      functionName: 'mint',
      msgValue: Moralis.Units.ETH((0.08 * qty).toString()),
      params: {
        quantity: qty,
      },
    };

    const tx = await executeTransaction(options);

    await waitForTransaction(tx);
  }

  async function reservedMint(signature: String[]) {

    console.log(signature);

    const options = {
      functionName: 'reservedMint',
      msgValue: Moralis.Units.ETH((0.08).toString()),
      params: {
        _merkleProof: signature,
      },
    };

    console.log(options);

    const tx = await executeTransaction(options);
    await waitForTransaction(tx);

  }

  async function waitForTransaction(tx: any) {
    setIsMinting(true);
    console.log('Waiting for tx confirmation...');

    await tx.wait();

    setIsMinting(false);
    console.log('Confirmed.');
  }

  return {publicMint, reservedMint, isMinting};
};
