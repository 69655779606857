import {useEffect, useState} from 'react';
import {useMoralis, useApiContract} from 'react-moralis';
import {useSaleState} from '../../hooks/useSaleState';
import {useMintMax} from '../../hooks/useMintMax';
import {usePresaleSig} from '../../hooks/usePresaleSig';
import Account from '../Account/Account';
import { useContext } from 'react';
import { UserContext } from '../../contexts/userContext'


function MintNFT(args) {
  // const { isAuthenticated, account, Moralis} = useMoralis();
  const user = useContext(UserContext)
  const {mintActive, fetchSaleStatus} = useSaleState(user.moralis);
  const {mintMax, fetchMintMax} = useMintMax(user.moralis);
  const {presaleSig, fetchSig, isPresaleWorthy} = usePresaleSig(user.moralis);
  const [quantity, setQuantity] = useState(0);

  useEffect(() => {
    if(user.acct){
      fetchMintMax();
      fetchSaleStatus();
      fetchSig(user.acct);
    }
  }, [user.acct])

  function increment() {
    if (quantity < mintMax) {
      setQuantity(quantity + 1);
    }
  }

  function decrement() {
    if (quantity > 0) {
      setQuantity(quantity - 1);
    }
  }

  function mintNFTs() {
    if (mintActive) {
      args.publicMint(quantity);
    }
  }

  function presaleMint() {
    args.reservedMint(presaleSig);
  }

  function PreSaleWorthy() {
    if(isPresaleWorthy) {
      return (
        <div className="mint2">
          <div className='increment'>
            <button className='presaleButton Shadow' onClick={() => presaleMint()}>Claim Whitelist</button>
          </div>
        </div>
      );
    } else {
      return(
        <div className="">
        </div>
      );
    }
  }

  if (!user.isAuth || !user.acct) {
    return (
      <div className="mint1 MintStatus">
          <Account />
      </div>
    );
  }

  if (mintActive) {
    if (args.isMinting) {
      return <div className="MintStatus">Please wait, minting NFTs...</div>;
    } else {
      return (
        <div className="mint2">
        <PreSaleWorthy/>
        <div className="increment">
            <button className="MintButton Shadow" onClick={() => mintNFTs()}>
              <span>Mint</span>
            </button>
          <div className="shrink">
            <button className="incrementbut1" onClick={() => decrement()}>
              −
            </button>
            <div className="sizing white-glassmorphism text-white text-xl Font Shadow">
              <p>Amount: {quantity} </p>
              <p>Price: {quantity*0.08} ETH </p>
            </div>
            <button className="incrementbut2" onClick={() => increment()}>
              +
            </button>
          </div>
        </div>
      </div>
      );
    }
  } else {
    return (
      <div className="mint3 Shadow Font text-white text-2xl">
        Sale has not started!
      </div>
    );
  }
}

export default MintNFT;
