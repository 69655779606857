import {useState} from 'react';
import fetchContractValue from '../utils/fetchContractValue';

export const useMintMax = (Moralis: any) => {
  const [mintMax, setMintMax] = useState(0);

  function fetchMintMax() {
    fetchContractValue('maxPublicMintPerAddress', Moralis).then(value => {
      const result = Number(value);
      setMintMax(result);
    });
  }

  return {mintMax, fetchMintMax};
};
