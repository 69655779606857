import MintNFT from './Contracts/MintNFT';
import MintBar from './Mint/MintBar';
import Marquee from 'react-fast-marquee';
import Machine from './Mint/Machine';
import Display from './Mint/Display'
import mashup from '../media/mashup.gif';
import logo from '../media/thelogo.gif';

import {useMint} from './../hooks/useMint';

import '../App.css';
import { useContext } from 'react';
import { UserContext } from '../contexts/userContext';

function Mint() {
  const user = useContext(UserContext);
  const {publicMint, reservedMint, isMinting} = useMint(user.moralis);


  return (
    <div className='Mint gradient-bg-welcome'>
      <Marquee className="mint-marquee" direction='right' speed={50} gradient={false}>
        <span className="uppercase">MINTING NOW ·&nbsp;</span>
        <span className="uppercase">RETRO ROLLERS ·&nbsp;</span>
        <span className="uppercase">MINTING NOW ·&nbsp;</span>
        <span className="uppercase">RETRO ROLLERS ·&nbsp;</span>
        <span className="uppercase">MINTING NOW ·&nbsp;</span>
        <span className="uppercase">RETRO ROLLERS ·&nbsp;</span>
        <span className="uppercase">MINTING NOW ·&nbsp;</span>
        <span className="uppercase">RETRO ROLLERS ·&nbsp;</span>
        <span className="uppercase">MINTING NOW ·&nbsp;</span>
        <span className="uppercase">RETRO ROLLERS ·&nbsp;</span>
        <span className="uppercase">MINTING NOW ·&nbsp;</span>
        <span className="uppercase">RETRO ROLLERS ·&nbsp;</span>
        <span className="uppercase">MINTING NOW ·&nbsp;</span>
        <span className="uppercase">RETRO ROLLERS ·&nbsp;</span>
        <span className="uppercase">MINTING NOW ·&nbsp;</span>
        <span className="uppercase">RETRO ROLLERS ·&nbsp;</span>
        <span className="uppercase">MINTING NOW ·&nbsp;</span>
        <span className="uppercase">RETRO ROLLERS ·&nbsp;</span>
        <span className="uppercase">MINTING NOW ·&nbsp;</span>
        <span className="uppercase">RETRO ROLLERS ·&nbsp;</span>
        <span className="uppercase">MINTING NOW ·&nbsp;</span>
        <span className="uppercase">RETRO ROLLERS ·&nbsp;</span>
        <span className="uppercase">MINTING NOW ·&nbsp;</span>
        <span className="uppercase">RETRO ROLLERS ·&nbsp;</span>
        <span className="uppercase">MINTING NOW ·&nbsp;</span>
        <span className="uppercase">RETRO ROLLERS ·&nbsp;</span>
      </Marquee>
      <MintBar />
      <Marquee className="mint-marquee2" direction='left' speed={50} gradient={false}>
        <span className="uppercase">MINTING NOW ·&nbsp;</span>
        <span className="uppercase">RETRO ROLLERS ·&nbsp;</span>
        <span className="uppercase">MINTING NOW ·&nbsp;</span>
        <span className="uppercase">RETRO ROLLERS ·&nbsp;</span>
        <span className="uppercase">MINTING NOW ·&nbsp;</span>
        <span className="uppercase">RETRO ROLLERS ·&nbsp;</span>
        <span className="uppercase">MINTING NOW ·&nbsp;</span>
        <span className="uppercase">RETRO ROLLERS ·&nbsp;</span>
        <span className="uppercase">MINTING NOW ·&nbsp;</span>
        <span className="uppercase">RETRO ROLLERS ·&nbsp;</span>
        <span className="uppercase">MINTING NOW ·&nbsp;</span>
        <span className="uppercase">RETRO ROLLERS ·&nbsp;</span>
        <span className="uppercase">MINTING NOW ·&nbsp;</span>
        <span className="uppercase">RETRO ROLLERS ·&nbsp;</span>
        <span className="uppercase">MINTING NOW ·&nbsp;</span>
        <span className="uppercase">RETRO ROLLERS ·&nbsp;</span>
        <span className="uppercase">MINTING NOW ·&nbsp;</span>
        <span className="uppercase">RETRO ROLLERS ·&nbsp;</span>
        <span className="uppercase">MINTING NOW ·&nbsp;</span>
        <span className="uppercase">RETRO ROLLERS ·&nbsp;</span>
        <span className="uppercase">MINTING NOW ·&nbsp;</span>
        <span className="uppercase">RETRO ROLLERS ·&nbsp;</span>
        <span className="uppercase">MINTING NOW ·&nbsp;</span>
        <span className="uppercase">RETRO ROLLERS ·&nbsp;</span>
        <span className="uppercase">MINTING NOW ·&nbsp;</span>
        <span className="uppercase">RETRO ROLLERS ·&nbsp;</span>
      </Marquee>
      <div className='mint-grid-2'>
        <div className="logo Font Shadow text-white text-6xl">
          <img src={logo} alt="" />
        </div>
        <div className="type text-white text-4xl Font Shadow">
          <p>Roll Anywhere. Roll Everywhere.</p>
        </div>
        <div className="mashup">
          <div className= "white-glassmorphism Card">
            <div className="mash">
              <img src={mashup} alt="" />
            </div>
          </div>
        </div>
        <MintNFT
          publicMint={publicMint}
          reservedMint={reservedMint}
          isMinting={isMinting}
        />
        <Machine
          isMinting={isMinting}
        />
        </div>
        <Display />
    </div>
  );
}

export default Mint;
