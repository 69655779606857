import { useState } from "react";

interface Score {
    rank: any;
    walletAddress: any;
    tokenId: any;
    score: any;
}

const api = process.env.REACT_APP_LEADERBOARD_API;


export const useHighScores = () => {
    const [highScore, setHighScore] = useState<Array<Score>>([]);

    // console.log("API: "+ api);

    async function fetchHighScores() {
        await fetch(api)
            .then(response => {
                // console.log(response)
                return response.json()
            }).then(data => {
                setHighScore(data)
            })
    }

    return {highScore, fetchHighScores}
};
