import Marquee from 'react-fast-marquee';
import gif1 from '../../media/roller1.gif';
import gif2 from '../../media/roller2.gif';
import gif3 from '../../media/roller3.gif';
import gif4 from '../../media/roller4.gif';
import gif5 from '../../media/roller5.gif';
import gif6 from '../../media/roller6.gif';
import gif7 from '../../media/roller7.gif';
import gif8 from '../../media/roller8.gif';
import box1 from '../../media/box1.png';
import box2 from '../../media/box2.png';
import box3 from '../../media/box3.png';
import box4 from '../../media/box4.png';
import roadmap from '../../media/roadmap.png';

interface Cards {
  title: any;
  subtitle: any;
}
const PhaseCard = (data: Cards) => (
  <div className="flex flex-row justify-start items-start white-glassmorphism p-3 m-8 hover:shadow-xl">
    <div className="ml-5 flex flex-col flex-1">
      <h3 className="Font mt-2 text-3xl bg-clip-text text-transparent bg-gradient-to-r from-pink-500 to-violet-500">
        {data.title}
      </h3>
      <p className="Font leading-tight mt-3 mb-3 text-white text-xl md:w-10/12">{data.subtitle}</p>
    </div>
  </div>
);

const Roadmap = () => (
  <div id="Roadmap"
    className="Bigmap justify-center items-center gradient-bg-services"
  >
    <div className="Container">
      <img className="Roadmap" src={roadmap} alt="Roadmap" />
      <div className='grid'>
        <div className='box floating'>
          <img src={box1} alt="" />
          <img src={box3} alt="" />
          <img src={box2} alt="" />
          <img src={box4} alt="" />
        </div>
        <div>
          <PhaseCard
          title="Phase 1: 🚧 UNDER CONSTRUCTION 🚧"
          subtitle="🚧 8,888 unique genesis rollers are minted and 100 ETH will be allocated towards our promotional prize pool.
          Over 7 days, players will be able to compete and earn their place on the leaderboard. When the play period ends,
          our prize poll will be distributed among the top 250 highscores. The higher your rank, the more ETH you will
          receive (more info coming soon). 🚧"
          />
          <PhaseCard
          title="Phase 2: 🚧 UNDER CONSTRUCTION 🚧"
          subtitle="🚧 Players will be able to compete for daily highscores and earn $ROLL tokens based on leaderboard placement.
          Every player on the leaderboard will earn $ROLL but the quantity will be dependent on where you place. The daily distribution
          will follow a normal curve. 🚧"
          />
          <PhaseCard
          title="Phase 3: 🚧 UNDER CONSTRUCTION 🚧"
          subtitle="🚧 $ROLL will be used to customize your roller and gameplay experience, and participate in tournaments with a minimum
          $ROLL buy-in. 🚧"
          />
          <PhaseCard title="Phase 4: 🚧 UNDER CONSTRUCTION 🚧" subtitle="🚧 Retro Rollers 2.0 🚧" />
        </div>
        <div className='box floating'>
          <img src={box1} alt="" />
          <img src={box3} alt="" />
          <img src={box2} alt="" />
          <img src={box4} alt="" />
        </div>
      </div>
    </div>
      <Marquee className="hide" gradient={false} direction="left" gradientWidth="0" speed={200}>
        <div className="inline-flex justify-between mt-6">
          <img className="h-64 w-64 mx-7" src={gif1} />
          <img className="h-64 w-64 mx-7" src={gif2} />
          <img className="h-64 w-64 mx-7" src={gif3} />
          <img className="h-64 w-64 mx-7" src={gif4} />
          <img className="h-64 w-64 mx-7" src={gif5} />
          <img className="h-64 w-64 mx-7" src={gif6} />
          <img className="h-64 w-64 mx-7" src={gif7} />
          <img className="h-64 w-64 mx-7" src={gif8} />
        </div>
      </Marquee>
  </div>
);

export default Roadmap;
