import React from 'react';
import yeti from '../../media/yeti.gif';
import wai from '../../media/mashup.gif';
import ninja from '../../media/ninja.gif';
import zj0 from '../../media/zj0.jpg';
import team from '../../media/MeetTheTeam.png';
import myFrame from '../../media/frame.png';

interface Team {
  name: any;
  description: any;
  picture: any;
  twitter: any;
}
const TeamCard = (data: Team) => {
  return (
    <div
      className="m-2 flex flex-1
    2xl:min-w-[350px]
    2xl:max-w-[400px]
    sm:min-w-[270px]
    sm:max-w-[300px]
    min-w-full
    flex-col p-3"
    >
      <div className="flex flex-col items-center w-full my-2">
        <a href={data.twitter} target="_blank">
          <div className="flex align-center items-center justify-center">
            <img src={data.picture} alt="pfp" className="w-36 glow" />
            <img src={myFrame} alt="pfp" className="myFrame" />
          </div>
        </a>
        <p className="Font Shadow text-white text-2xl mt-5"> {data.name}</p>
        <p className="Font Shadow text-white text-lg mt-1"> {data.description}</p>
      </div>
    </div>
  );
};

const Team = () => {
  return (
    // <div className="flex w-full flex-col justify-center items-center 2xl:px-20 pt-16 gradient-bg-transactions">
    <div id="Team" className="Bigmap w-full justify-center items-center gradient-bg-transactions">
      <div className="Container">
        <img className="" src={team} alt="Meet The Team" />
        <div className="flex flex-col md:px-5 px-4 mt-5">
          <div className="flex flex-wrap justify-center items-center">
            <TeamCard
              name="SwetiYeti"
              description="Founder / Web3 Dev"
              twitter="https://twitter.com/swetiyeti_eth"
              picture={yeti}
            />
            <TeamCard
              name="TheOnlyWai"
              description="Artist"
              twitter="https://twitter.com/theonly_wai"
              picture={wai}
            />
            <TeamCard
              name="DopeAssNinja"
              description="Web3 Dev & Frontend Dev"
              twitter="https://twitter.com/_Dope_Ass_Ninja"
              picture={ninja}
            />
            <TeamCard
              name="Zj0"
              description="Game Dev"
              twitter="https://twitter.com/_Dope_Ass_Ninja"
              picture={zj0}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Team;
