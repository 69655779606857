import logo from '../../media/logo.gif';
import { Link } from 'react-router-dom';

const GAME_URL = process.env.REACT_APP_GAME_URL || "https://play.retrorollers.xyz/";

const Top = () => {
  return (
    <div id="Home" className="App-top">
      <div className="Container">
        <div className="grid">
          <div className="image">
            <img className="w-screen glow" src={logo} alt="" />
          </div>
          <div className="game-button justify-center align-center">
            <div className='inline-flex justify-center w-full gap-x-8'>
              <a
                href={GAME_URL}
                className="btn btn-1 Shadow">
                  Play
              </a>
              <Link className='btn btn-1 Shadow' to='/mint'>MINT</Link>
           </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Top;

