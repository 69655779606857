import Account from '../Account/Account';
import discord from '../../media/discord.svg';
import twitter from '../../media/twitter.svg';
import opensea from '../../media/opensea.svg';
import etherscan from '../../media/etherscan.svg';

import '../../App.css';
import { Link } from 'react-router-dom';

function Navbar() {
  return (
    <div className="App-header justify-between">
      <ul className="App-social mb-2">
        <li>
          <a
            href="https://discord.gg/retrorollers"
            target="_blank"
            rel="noreferrer"
          >
            <img className="drop-shadow-md" src={discord} alt="Discord" />
          </a>
        </li>
        <li>
          <a
            href="https://twitter.com/RetroRollersNFT"
            target="_blank"
            rel="noreferrer"
          >
            <img className="drop-shadow-md" src={twitter} alt="Twitter" />
          </a>
        </li>
        <li>
          <a
            href="https://opensea.io/collection/retrorollers"
            target="_blank"
            rel="noreferrer"
          >
            <img className="drop-shadow-md" src={opensea} alt="OpenSea" />
          </a>
        </li>
        <li>
          <a
            href="https://etherscan.io/address/0xc747e855fa8d46a38752aaac119f4ffd19b1354e"
            target="_blank"
            rel="noreferrer"
          >
            <img className="drop-shadow-md w-6" src={etherscan} alt="Etherscan" />
          </a>
        </li>
      </ul>
      <div className="Clinks">
        <div className="links Shadow text-white">
          <a className='' href="#Home">HOME</a>
          &nbsp;|&nbsp;
          <Link to="/leaderboard">LEADERBOARD</Link>
          &nbsp;|&nbsp;
          <a className='' href="#Roadmap">ROADMAP</a>
          &nbsp;|&nbsp;
          <a className='' href="#Team">TEAM</a>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
