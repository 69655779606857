import '../App.css';
import Navigation from './Main/Navigation';
import Top from './Main/Top';
import Roadmap from './Main/Roadmap';
import Team from './Main/Team';
import Footer from './Main/Footer';

function Main() {
  return (
    <div>
      <Navigation />
      <Top />
      <Roadmap />
      <Team />
      <Footer />
    </div>
  );
}

export default Main;
