import '../App.css';
import logo from '../media/roller-leaderboard.gif'

import Navbar from '../components/Leaderboard/LeaderNavigation';
import Board from '../components/Leaderboard/Board';
import Player from '../components/Leaderboard/Player';

function Leaderboard() {
    return (
        <div className="LeaderBG gradient-bg-welcome">
            <Navbar />
            <div className="Container">
                <img className='lb-logo' src={logo}></img>
            </div>
            <Board />
            <Player />
        </div>
    );
}

export default Leaderboard;
