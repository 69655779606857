import {useTotalSupply} from '../../hooks/useTotalSupply';
import { useEffect } from 'react';
import { useContext } from 'react';
import { UserContext } from '../../contexts/userContext'

import initial from '../../media/Slotmachine3.png';
import minting from '../../media/Mintmachine.gif';
import minted from '../../media/Slotmachine4.gif';

function Machine(args) {
const user = useContext(UserContext);
  const {totalSupply, fetchTotalSupply} = useTotalSupply(user.moralis);

  useEffect(() => {
      if(user.acct){
        fetchTotalSupply();
      }
    }, [user.acct]);

  const maxSupply = 5555;

    if(totalSupply === maxSupply){
        return(
        <div className="gas-container">
            <img src={minted} alt="" />
            <div className="Mint-count ShadowNew">
               <span>{totalSupply}</span>
            </div>
        </div>
     );
    }

    if(args.isMinting){
        return(
            <div className="gas-container">
                <img src={minting} alt="" />
                <div className="Mint-count ShadowNew">
                    <span>{totalSupply}</span>
                </div>
            </div>
        );
    } else {
        return(
            <div className="gas-container">
                <img src={initial} alt="" />
                <div className="Mint-count ShadowNew">
                   <span>{totalSupply}</span>
                </div>
            </div>
         );
    }
}
export default Machine;

