import { useEffect } from 'react';
import '../../App.css';
import { usePlayerData } from '../../hooks/usePlayerData';
import { UserContext } from '../../contexts/userContext';
import { useContext } from 'react';
import refresh from '../../media/refresh2.png'

const PlayerElement = ({score}) => {
    return (
        <tr>
            <td>{score.rank}</td>
            <td>{score.score}</td>
            <td>{score.tokenId}</td>
        </tr>
    )
}

function Player() {
    const { playerData, fetchPlayerData } = usePlayerData();
    const user = useContext(UserContext);

    useEffect (() => {
        if(user.acct){
            const thing = user.moralis.web3Library.utils.getAddress(user.acct);
            fetchPlayerData(thing);
        }
    }, [user.acct])

    function Refresh() {
        if(user.acct){
            const thing = user.moralis.web3Library.utils.getAddress(user.acct);
            fetchPlayerData(thing);
        }
    }

    return (
        <div className='Player black-glassmorphism text-white'>
            <div className='Font Shadow text-4xl theader flex align-center items-center gap-x-2'>
                &nbsp;
                <h1 className='bfont placement'>Player High Scores</h1>
                <button onClick={() => Refresh()}>
                    <img className="w-6" src={refresh} alt="" />
                </button>
            </div>
            <table>
                <thead className='Font Shadow hfont placement'>
                    <tr className=''>
                        <th  colSpan={1}>Rank</th>
                        <th  colSpan={1}>Score</th>
                        <th  colSpan={1}>Roller</th>
                    </tr>
                </thead>
                <tbody className='Font tfont'>
                {
                    playerData.map((score) =>
                        <PlayerElement score={score} key={score.rank} />)
                }
                </tbody>
                <tfoot>
                    <tr>
                        <th colSpan={5}></th>
                    </tr>
                </tfoot>
            </table>
        </div>
    )
}

export default Player;
