import {Avatar, Skeleton} from '@mui/material';
import Blockies from 'react-blockies';
import {useMoralis} from 'react-moralis';

interface BlockieProps {
  currentWallet?: boolean;
  address?: string;
  scale?: number;
  size?: number;
}

function Blockie({currentWallet, address, scale = 4, size = 8}: BlockieProps) {
  const {account, isAuthenticated} = useMoralis();

  if (address && (!account || !isAuthenticated)) {
    return (
      <Skeleton animation="wave" variant="circular">
        <Avatar
          sx={{width: `${scale * size}px`, height: `${scale * size}px`}}
        />
      </Skeleton>
    );
  }

  return (
    <Avatar sx={{width: `${scale * size}px`, height: `${scale * size}px`}}>
      <Blockies
        seed={currentWallet ? account!.toLowerCase() : address!.toLowerCase()}
        scale={scale}
        size={size}
      />
    </Avatar>
  );
}

export default Blockie;
