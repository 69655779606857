import {useState} from 'react';
import { useMoralis } from 'react-moralis';
import contractAbi from '../utils/contract.json';

const CONTRACT_ADDRESS = process.env.REACT_APP_CONTRACT_ADDRESS;

export const usePresaleSig = (Moralis: any) => {
  const [presaleSig, setPresaleSig] = useState<Array<String>>([]);
  const [isPresaleWorthy, setPresaleWorthy] = useState(false);

  async function fetchSig(address: string) {
    const response = await fetch(`./api/verify/${address}`);

    console.log(response);

    if (!CONTRACT_ADDRESS) {
      console.log('ERROR: Missing REACT_APP_CONTRACT_ADDRESS env variable');
      return '';
    }

    const sig = await response.json();
    console.log(sig['signature']);
    setPresaleSig(sig['signature']);

    console.log(sig);

    const options = {
      contractAddress: CONTRACT_ADDRESS,
      functionName: 'verify',
      abi: contractAbi,
      params: {
        target: address,
        _merkleProof: sig['signature'],
      }
    };

    const message = await Moralis.executeFunction(options);
    console.log('Verify function returned ' + message);

    setPresaleWorthy(message && true);
  }

  return {presaleSig, fetchSig, isPresaleWorthy};
};
