import {useState} from 'react';
import fetchContractValue from '../utils/fetchContractValue';

export const useSaleState = (Moralis: any) => {
  const [mintActive, setMintActive] = useState(false);

  function fetchSaleStatus() {
      fetchContractValue('mintActive', Moralis).then(value => {
        setMintActive(Boolean(value));
      });
    }

  return {mintActive, setMintActive, fetchSaleStatus};
};
