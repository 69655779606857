/* eslint-disable */
import * as React from 'react';
import Button from '@mui/material/Button';
import {getEllipsisTxt} from '../formatters';
import Blockie from '../Blockie';
import AccountDialog from './AccountDialog';
import ConnectWalletDialog from './ConnectWalletDialog';
import { useDomain } from '../../hooks/useDomain';
import { UserContext } from '../../contexts/userContext';

function Account() {
  const [modalOpen, setModalOpen] = React.useState(false);
  const [authModalOpen, setAuthModalOpen] = React.useState(false);

  const user = React.useContext(UserContext);
  const {domain, fetchDomain} = useDomain(user.moralis);

  React.useEffect(() => {
    if(user.acct){
      fetchDomain(user.acct);
    }
  }, [user.acct])

  if (!user.isAuth || !user.acct) {
    return (
      <div>
        <Button
          variant="contained"
          size="large"
          onClick={() => setAuthModalOpen(true)}
          sx={{
            height: 36,
            textTransform: 'none',
            px: 1.75,
            borderRadius: 2,
            lineHeight:1,
            fontSize: '25px',
            fontWeight: 'bold',
            textShadow: '3px 3px 2px rgba(211, 13, 162, 0.651)',
            backgroundImage: 'linear-gradient(to right top, #678cf2 0%, #ff59bd 100%)',
          }}
        >
          CONNECT WALLET
        </Button>
        <ConnectWalletDialog open={authModalOpen} setOpen={setAuthModalOpen} />
      </div>
    );
  }

  return (
    <React.Fragment>
      <Button
        variant="contained"
        size="large"
        onClick={() => setModalOpen(true)}
        sx={{
          height: 36,
          textTransform: 'none',
          py: 0,
          px: 1.75,
          fontSize: '20px',
          borderRadius: 2,
          '&.MuiButton-contained': {
            backgroundColor: '#f4f4f4',
            color: '#000000',
          },
        }}
        endIcon={<Blockie currentWallet scale={3} />}
      >
        {domain ?? getEllipsisTxt(user.acct, 5)}
      </Button>
      <AccountDialog open={modalOpen} setOpen={setModalOpen} />
    </React.Fragment>
  );
}

export default Account;
