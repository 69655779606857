import { useState } from "react";

interface Score {
    rank: any;
    tokenId: any;
    score: any;
    walletAddress: any
}

const api = process.env.REACT_APP_PLAYER_DATA_API

export const usePlayerData = () => {
    const [playerData, setPlayerData] = useState<Array<Score>>([]);

    async function fetchPlayerData(address: string) {
        await fetch(`${api}${address}`)
            .then(response => {
                return response.json()
            }).then(data => {
                setPlayerData(data)
            })
    }

    return {playerData, fetchPlayerData}
};
