import discord from '../../media/discord.svg';
import twitter from '../../media/twitter.svg';
import opensea from '../../media/opensea.svg';

const Appfooter = () => (
  <div className="w-full flex md:justify-center justify-between items-center flex-col p-4 gradient-bg-footer">
    <div className="w-[90%] flex sm:flex-row flex-col justify-between items-center my-4">
      <ul className="flex flex-1 justify-end space-x-4 sm:mt-0 mt-5 w-full">
        <li>
          <a
            href="https://discord.gg/retrorollers"
            target="_blank"
            rel="noreferrer"
          >
            <img src={discord} alt="Discord" />
          </a>
        </li>
        <li>
          <a
            href="https://twitter.com/RetroRollersNFT"
            target="_blank"
            rel="noreferrer"
          >
            <img src={twitter} alt="Twitter" />
          </a>
        </li>
        <li>
          <a
            href="https://opensea.io/collection/retrorollers"
            target="_blank"
            rel="noreferrer"
          >
            <img src={opensea} alt="OpenSea" />
          </a>
        </li>
      </ul>
    </div>

    <div className="sm:w-[90%] w-full h-[0.25px] bg-gray-400 mt-2" />

    <div className="sm:w-[90%] w-full flex justify-between items-center mt-3">
      <p className="text-white text-left text-xs">@RetroRollers</p>
      <p className="text-white text-right text-xs">All rights reserved</p>
    </div>
  </div>
);

export default Appfooter;
